/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "'Choose Nissan' was a site developed for Nissan Canada to feature their monthly specials, which differ across Canada. The user location is detected, and the user is redirected to the correct region. There were five regional sites in total, one in English and one in French."), "\n", React.createElement(_components.p, null, "It was relatively easy to accomplish responsiveness using Foundation, and there wasn't much custom javascript to do except the dealer locator. The site is built on Glovebox, so most of the backend was already available - I just had to write some PHP to get custom info from the database. In addition to HTML and PHP, there were a few tools written in Python to populate certain content on the site."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
